<template>
	<div class="configuration_view">
		<div class="flex_r">
			<div class="img ml20 mr20">
				<img width="360" height="360" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
				<div class="flex_r f_jc_b pt14 pb14">
					<img width="60" height="60" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
					<img width="60" height="60" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
					<img width="60" height="60" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
					<img width="60" height="60" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
					<img width="60" height="60" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
				</div>
				<div class="pt8 pb8 mb30">月销量、累计评价、收藏</div>
			</div>
			<div class="goods_info flex1">
				<div class="pt8 pb8 mb30 ml20 mr20 fs22 fw_b">韩国悠宜unny美妆蛋李佳琦粉扑海绵彩妆化妆不吃粉球斜切彩妆三个</div>
				<div class="flex_r f_ai_c">
					<div class="pt8 pb8 mb30 ml20 mr10">店铺：欧欧韩国美妆 十年老店</div>
					<div class="pt8 pb8 mb30 ml10 mr10">
						<ul class="flex_r">
							<li class="mr6">
								<span>描述</span>
								<span class="cr_ff0">4.8</span>
							</li>
							<li class="mr6">
								<span>服务</span>
								<span class="cr_ff0">4.8</span>
							</li>
							<li class="mr6">
								<span>评分</span>
								<span class="cr_ff0">4.8</span>
							</li>
						</ul>
					</div>
				</div>
				<div  class="flex_r f_ai_c">
					<div class="pt8 pb8 mb30 ml20 mr10">在售价:￥39</div>
					<!-- <div class="tc br_1_eee pt8 pb8 mb30 w_100 ml10 mr10">最低价</div>
					<div class="tc br_1_eee pt8 pb8 mb30 w_100 ml10 mr10">历史最低价</div>
					<div class="tc br_1_eee pt8 pb8 mb30 w_100 ml10 mr10">最低价说明</div> -->
				</div>
				<!-- <div class="w_100 pt8 pb8 bg_red brs_4 br_1_eee tc ml20 cr_fff">查看价格走势</div> -->
				<div class="specification ml20">
					<span>规格:</span>
					<span class="ml4 mr10">颜色分类</span>
					<el-button type="primary">1盒3支袋</el-button>
				</div>
			</div>
		</div>
		<div>
			<e-form class="w75" :formConfList="goodsDetailFormList">
				<template #5>
					<div>
						<el-tag class="cur_p mr10" closable title="XS（建议95斤以内）黑色高腰款-主图款/￥399.00">XS（建议95斤以内）黑色高腰款-主图款/￥399.00</el-tag>
						<el-tag class="cur_p" type="danger" @click="$refs.dialogRef.handleOpen()">自定义报名规格</el-tag>
					</div>
				</template>
				<template #33>
					<editor />
				</template>
			</e-form>
			<div class="tc">
				<el-button type="primary">保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import {goodsDetailFormList} from '@data/goods'
export default {
	name: 'ConfigurationView',
	data() {
		return {
			goodsDetailFormList
		}
	},
	methods: {
		handleClick() {}
	}
}
</script>

<style lang="scss" scoped>
.configuration_view {
	width: 1270px;
	margin: 60px auto 0 auto;
}
</style>